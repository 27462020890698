import { KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(
  keycloak: KeycloakService,
  keycloakHost: string,
) {
  return keycloak.init({
    config: {
      url: `${keycloakHost}/auth`,
      realm: 'kwh-courier',
      clientId: 'courier-ui',
    },
    loadUserProfileAtStartUp: true,
    initOptions: {
      checkLoginIframe: false,
      onLoad: 'login-required',
    },
  });
}
